import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import CustomToast from "../../reusable/CustomToast";
import { useState } from "react";

//axios
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faEdit,
  faEllipsisH,
  faKeyboard,
  faStar,
  faStarHalf,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import AddEditReviews from "../../pages/Modals/AddEditReviews";
import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import AddEditReviewReply from "../../pages/Modals/AddEditReviewReply";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import CustomPagination from "../Pagination";
import { faFlipboard } from "@fortawesome/free-brands-svg-icons";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

const ReviewsTable = ({
  reviews,
  refetchReviews,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  //States
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);

  //Add Edit Modal states
  const [showModal, setShowModal] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [reviewReplyData, setReviewReplyData] = useState({
    id: "",
    review_reply: "",
  });
  const [showReplyModal, setShowReplyModal] = useState(false);

  //dispatch
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.login.userData).role_id === 1;
  const { role } = useSelector((state) => state.login.userData);
  const { plan_features } = useSelector((state) => state.plan.planData);

  //Functions
  const deleteHandler = async (id) => {
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "delete",
        url: `/dis/review/delete/${id}`,
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchReviews();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };

  // const renderTooltip = () => {
  //   console.log("yec hala");
  //   return (

  //   );
  // };
  const updateHandler = async (data) => {
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "post",
        url: `/dis/review/update/${data.id}`,
        data: data,
      });
      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchReviews();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="p-0 pb-4 justify-content-center">
          {reviews?.length > 0 ? (
            <Table responsive hover className="user-table min-height">
              <thead>
                <tr>
                  <th className="border-bottom">Disp</th>

                  <th className="border-bottom">Ratings</th>
                  <th className="border-bottom">Comment</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Review Date</th>
                  {refetchReviews && <th className="border-bottom">Action</th>}
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review.id}>
                    <td text-label="Disp">
                      <span className="fw-normal">
                        {review.dispensary.name}
                      </span>
                    </td>

                    <td text-label="Rating">
                      <span className="fw-normal">
                        {[...Array(Math.round(review.rating))].map((_, i) => (
                          <FontAwesomeIcon icon={faStar} color="#65ac4b" />
                        ))}
                        {review.rating > Math.round(review.rating) && (
                          <FontAwesomeIcon icon={faStarHalf} color="#65ac4b" />
                        )}
                      </span>
                    </td>
                    <td text-label="Comment">
                      <span className="fw-normal">{review.comment}</span>
                    </td>

                    <td text-label="Status">
                      <span className="fw-normal">
                        {review.status ? "Published" : "Pending"}
                      </span>
                    </td>
                    <td text-label="Review Date">
                      <span className="fw-normal">
                        {moment(review.created_at).format("DD MMM YYYY")}
                      </span>
                    </td>

                    {refetchReviews && (
                      <td>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 3000 }}
                          overlay={
                            role === "partner" &&
                            !plan_features?.review_management ? (
                              <Tooltip id="button-tooltip">
                                You don't have access{" "}
                                <Link
                                  className="text-white text-underline"
                                  to={Routes.buyPlan.path}
                                >
                                  Click here
                                </Link>{" "}
                                to Upgrade{" "}
                                <FontAwesomeIcon icon={faArrowCircleUp} /> your
                                plan to get full access
                              </Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          <Dropdown as={ButtonGroup} drop="down-centered">
                            <Dropdown.Toggle
                              as={Button}
                              split
                              variant="link"
                              className="text-dark m-0 p-0"
                              disabled={
                                role === "partner" &&
                                !plan_features?.review_management
                              }
                            >
                              <span className="icon icon-sm">
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="icon-dark"
                                />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {admin && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setShowModal(true);
                                    setReviewData({
                                      dis_id: review.dis_id,
                                      id: review.id,
                                      rating: review.rating,
                                      comment: review.comment,
                                      status: review.status,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="me-2"
                                  />{" "}
                                  Edit
                                </Dropdown.Item>
                              )}
                              {admin && (
                                <Dropdown.Item
                                  className={
                                    review.status ? "text-danger" : "text-info"
                                  }
                                  onClick={() =>
                                    updateHandler({
                                      dis_id: review.dis_id,
                                      id: review.id,
                                      rating: review.rating,
                                      comment: review.comment,
                                      status: review.status ? 0 : 1,
                                      owner_reply: review.owner_reply,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      review.status
                                        ? faArrowAltCircleLeft
                                        : faFlipboard
                                    }
                                    className="me-2"
                                  />{" "}
                                  {review.status ? "Withdraw" : "Publish"}
                                </Dropdown.Item>
                              )}
                              {admin && (
                                <Dropdown.Item
                                  className="text-danger"
                                  onClick={() => deleteHandler(review.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className="me-2"
                                  />{" "}
                                  Remove
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                onClick={() => {
                                  setReviewReplyData({
                                    dis_id: review.dis_id,
                                    id: review.id,
                                    rating: review.rating,
                                    comment: review.comment,
                                    status: review.status,
                                    owner_reply: review.owner_reply,
                                  });
                                  setShowReplyModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faKeyboard}
                                  className="me-2"
                                />{" "}
                                Reply on review
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
              {" "}
              No Data Available.....
            </Row>
          )}
        </Card.Body>
        {totalPages > 1 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>
      {showModal && (
        <AddEditReviews
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setReviewData({});
          }}
          reviewData={reviewData}
          type="edit"
          refetchReviews={refetchReviews}
        />
      )}
      {showReplyModal && (
        <AddEditReviewReply
          reviewReplyData={reviewReplyData}
          show={showReplyModal}
          handleClose={() => setShowReplyModal(false)}
          refetchReviews={refetchReviews}
          setShowToast={setShowToast}
          setToastBody={setToastBody}
          setSuccess={setSuccess}
        />
      )}
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default ReviewsTable;
