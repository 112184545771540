import {
  faEnvelope,
  faPhoneAlt,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button } from "react-bootstrap";
import ShareButton from "./ShareButton";
import { useState } from "react";
const Actions = ({ mailId, phoneNum }) => {
  const [show, setShow] = useState(false);
  const clickHandler = (action, actionValue) => {
    window.location.href = `${action}:${actionValue}`;
    // e.preventDefault();
  };
  const url = window.location.pathname;

  return (
    <Container fluid className="mt-4 mb-5">
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            className="fontweigh-500"
            variant="light"
            onClick={() => clickHandler("tel", phoneNum)}
            disabled={!phoneNum}
            // disabled={!phoneNum || plan_id === 1}
          >
            <FontAwesomeIcon icon={faPhoneAlt} /> Call now
          </Button>
        </Col>

        <Col className="d-flex justify-content-center">
          <Button
            className="fontweigh-500"
            variant="light"
            onClick={() =>
              clickHandler(
                "mailto",
                !mailId ? "contact@weedtome.com" : mailId
                // !mailId || plan_id === 1 ? "contact@weedtome.com" : mailId
              )
            }
            // disabled={!mailId || plan_id === 1}
          >
            {" "}
            <FontAwesomeIcon icon={faEnvelope} /> Email
          </Button>
        </Col>

        <Col className="d-flex justify-content-center">
          <Button
            className="fontweigh-500"
            variant="light"
            onClick={() => {
              setShow((prevShow) => !prevShow);
            }}
          >
            <FontAwesomeIcon icon={faShare} /> Share
          </Button>
        </Col>
      </Row>
      <ShareButton url={url} show={show} handleClose={() => setShow(false)} />
    </Container>
  );
};
export default Actions;
