import dummy_cover_image from "../../assets/img/dummy_cover_image.webp";
export default function LoadingPlaceHolder({ height, image, width }) {
  return (
    <>
      {image ? (
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${dummy_cover_image})`,

            height: `${height}px`,
            width: `${width}%`,
            margin: "auto",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* <Image src={dummy_cover_image} alt="dummy cover image" /> */}
        </div>
      ) : (
        <div
          style={{ height: `${height}px` }}
          className="loading-data fs-5 mt-4"
        >
          Loading Data...
        </div>
      )}
    </>
  );
}
