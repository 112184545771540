import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import axios from "../../../axios";
import ImageUploader from "../../../reusable/Helper/ImageUploader";
import MultImageUploader from "../../../reusable/Helper/MultImageUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { valuehandler } from "../../../components/Helpers/HelperFunctions";

const GeneralInfo = React.memo(
  ({
    form,
    setForm,
    errors,
    handleChange,
    logo,
    setLogo,
    cover,
    setCover,
    galleryImages,
    setGalleryImages,
  }) => {
    // Fetch dispensary types
    const fetchDispTypes = async () => {
      const { data } = await axios.get("/distypes");
      return data.data;
    };

    // Fetch timezones
    const fetchTimezones = async () => {
      const { data } = await axios.get("/timezones");
      return data.data;
    };

    // Queries for dispensary types and timezones
    const { data: dispTypes = [] } = useQuery({
      queryKey: ["dispensaryCategory"],
      queryFn: fetchDispTypes,
      staleTime: Infinity,
      cacheTime: Infinity,
    });

    const { data: timezones = [] } = useQuery({
      queryKey: ["timezone"],
      queryFn: fetchTimezones,
      staleTime: Infinity,
      cacheTime: Infinity,
    });

    // Memoized options for Select components to avoid recalculating on each render
    const dispensaryTypeOptions = useMemo(
      () => dispTypes.map((disp) => ({ label: disp.title, value: disp.id })),
      [dispTypes]
    );

    const timezoneOptions = useMemo(
      () =>
        timezones.map((tmz) => ({ label: tmz.timezone_title, value: tmz.id })),
      [timezones]
    );

    return (
      <Container fluid className="mt-4">
        <h3 className="text-decoration-underline text-center mb-4">General</h3>
        <Row className="justify-content-center">
          <Col className="mb-3 text-center">
            <h5>Logo</h5>
          </Col>
          <Col className="mb-3 text-center">
            <h5>
              Cover Image<span className="text-danger">*</span>
            </h5>
            <p className="text-danger">{errors?.cover_image}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mb-3 d-flex justify-content-center mb-5">
            <ImageUploader img={logo} setImg={setLogo} size="180 X 180" />
          </Col>
          <Col className="mb-3 d-flex justify-content-center">
            <ImageUploader img={cover} setImg={setCover} size="1920 X 400" />
          </Col>
        </Row>

        <Row>
          <Row className="mb-2">
            <h5 className="d-flex justify-content-center">Gallery</h5>
          </Row>
          <MultImageUploader
            images={galleryImages}
            setImages={setGalleryImages}
          />
        </Row>

        <Row>
          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup id="Dispensary Name">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Enter your dispensary name</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  Dispensary Name<span className="text-danger">*</span>{" "}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <FormControl
                required
                name="name"
                type="text"
                value={form.name}
                onChange={(e) => handleChange(e, setForm)}
                placeholder="Dispensary Name"
              />
              <p className="text-danger">{errors?.name}</p>
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup id="Dispensary Type Id">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Choose type of your dispensary</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  Dispensary Type<span className="text-danger">*</span>{" "}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <Select
                placeholder="Dispensary Type"
                value={valuehandler(dispensaryTypeOptions, form.dis_type_id)}
                onChange={(e) => setForm({ ...form, dis_type_id: e.value })}
                options={dispensaryTypeOptions}
              />
              <p className="text-danger">{errors?.dis_type_id}</p>
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup id="Timezone">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Select timezone of your dispensary</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  TimeZone<span className="text-danger">*</span>{" "}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <Select
                placeholder="Timezone"
                value={valuehandler(timezoneOptions, form.timezone_id)}
                onChange={(e) => setForm({ ...form, timezone_id: e.value })}
                options={timezoneOptions}
              />
              <p className="text-danger">{errors?.timezone_id}</p>
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup id="License Number">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Enter your license number</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  License Number<span className="text-danger">*</span>{" "}
                  <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <FormControl
                required
                type="text"
                name="license_number"
                value={form.license_number}
                onChange={(e) => handleChange(e, setForm)}
                placeholder="License Number"
              />
              <p className="text-danger">{errors?.license_number}</p>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default GeneralInfo;
