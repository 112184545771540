import { createSlice } from "@reduxjs/toolkit";
// Function to get user data from localStorage
const getPartnerPlanData = () => {
  const plan = JSON.parse(localStorage.getItem("plan_id"));
  return {
    amount: 0,
    expiry_date: "",
    pending_days: 0,
    plan_features: {
      analytics: 0,
      can_add_blogs: 0,
      can_add_brands: 0,
      can_add_deals: 0,
      can_add_dispensaries: 0,
      can_add_products: 0,
      can_add_to_cart: 0,
      contact_info: 0,
      dis_display_on_home: 0,
      dispensaries_limit: 0,
      is_verified: 0,
      link_products: 0,
      order_management: 0,
      ranked_in_top_10: 0,
      response_to_comment: 0,
      review_management: 0,
    },
    plan_id: plan?.plan_id || 0,
    plan_name: "",
    remaining_amount: "",
  };
};

const initialState = {
  planData: getPartnerPlanData(),
};
export const planDataSlice = createSlice({
  name: "plan_data",
  initialState,
  reducers: {
    planDataSet: (state, action) => {
      state.planData = action.payload;
    },
  },
});

// Export actions generated by createSlice
export const { planDataSet } = planDataSlice.actions;

// Export reducer
export default planDataSlice.reducer;
