import { StandaloneSearchBox } from "@react-google-maps/api";
import { useRef } from "react";

const PlaceAutocomplete = ({ setNewLocation }) => {
  const inputRef = useRef();

  const handlePlaceChange = () => {
    const [place] = inputRef.current.getPlaces();

    if (place) {
      const address = place.address_components;
      const address_line1 =
        address.find((ad) => ad.types.includes("street_number"))?.long_name ||
        "";
      const address_line2 =
        address.find((ad) => ad.types.includes("route"))?.long_name || "";
      const city =
        address.find((ad) => ad.types.includes("locality"))?.long_name || "";
      const state =
        address.find((ad) => ad.types.includes("administrative_area_level_1"))
          ?.long_name || "";
      const zip_code =
        address.find((ad) => ad.types.includes("postal_code"))?.long_name || "";
      const country =
        address.find((ad) => ad.types.includes("country"))?.short_name || "";
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();

      // Update newLocation only when a selection is confirmed
      setNewLocation({
        address_line1,
        address_line2,
        city,
        state,
        country,
        lat,
        long,
        zip_code,
      });
    }
  };

  return (
    <div className="mb-2 me-2">
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChange}
      >
        <input
          type="text"
          className="form-control"
          placeholder="Enter Location"
        />
      </StandaloneSearchBox>
    </div>
  );
};

export default PlaceAutocomplete;
