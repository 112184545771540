import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import LocationGoogleMap from "../Explore/LocationGoogleMap";
import { useEffect, useState } from "react";
import { handleChange } from "../../../components/Helpers/HelperFunctions";
import PlaceAutocomplete from "../../../reusable/Autocomplete/PlaceAutocomplete";
import axios from "../../../axios";
import CustomToast from "../../../reusable/CustomToast";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";

const ClinicAppointment = ({ clinicData }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);
  const [newLocation, setNewLocation] = useState({ city: "", state: "" });
  const [placeAutocompleteKey, setPlaceAutocompleteKey] = useState(Date.now());
  const initialFormData = {
    name: "",
    phone_no: "",
    email: "",
    city: "",
    state: "",
    lead_type: "appointment",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState({
    name: "",
    phone_no: "",
    email: "",
    city: "",
    state: "",
  });
  const disData = [
    {
      id: clinicData?.id,
      latitude: clinicData.latitude,
      longitude: clinicData.longitude,
      logo: clinicData.logo,
    },
  ];
  const center = {
    lat: Number(clinicData.latitude),
    lng: Number(clinicData.longitude),
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      city: newLocation?.city,
      state: newLocation?.state,
    }));
  }, [newLocation]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "name",
      "email",
      "phone_no",
      "city",
      "state",
      "lead_type",
    ];

    // Clear all errors initially
    let newErrors = {};

    // Check for empty required fields and set errors accordingly
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `*${field.replace("_", " ")} is mandatory`;
      }
    });
    setError(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setShowToast(true);
      setToastBody("Please fill all the mandatory fields");
      setSuccess(false);
    }
    // If there are no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      dispatch(loadingSet(true));
      const appointmentData = new FormData();
      appointmentData.append("name", formData.name);
      appointmentData.append("email", formData.email);
      appointmentData.append("phone_no", formData.phone_no);
      appointmentData.append("city", formData.city);
      appointmentData.append("state", formData.state);
      appointmentData.append("lead_type", formData.lead_type);
      try {
        const res = await axios({
          method: "POST",
          url: "/mmj/lead",
          data: appointmentData,
        });
        setShowToast(true);
        setToastBody(res.data.message);
        setSuccess(true);
        setFormData(initialFormData);
        setNewLocation({ city: "", state: "" });
        setPlaceAutocompleteKey(Date.now());
      } catch (err) {
        setShowToast(true);
        setToastBody(err.response?.data?.message || "An error occurred");
        setSuccess(false);
      } finally {
        dispatch(loadingSet(false));
      }
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col md={6} className="mb-4">
          <Card>
            <CardHeader className="fs-5 fontWeigh-500 text-dark">
              Request an appointment
            </CardHeader>
            <CardBody>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3 ">
                  <Form.Label>
                    Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="Enter your first name"
                  />
                  <p className="text-danger">{error.name}</p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Phone Number<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    name="phone_no"
                    type="number"
                    value={formData.phone_no}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="Enter your Phone Number"
                  />
                  <p className="text-danger">{error.phone_no}</p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="Enter your Email"
                  />
                  <p className="text-danger">{error.email}</p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    City<span className="text-danger">*</span>
                  </Form.Label>
                  <PlaceAutocomplete
                    key={placeAutocompleteKey}
                    setNewLocation={setNewLocation}
                  />{" "}
                  <p className="text-danger">{error.city}</p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    State<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    name="state"
                    type="text"
                    value={formData.state}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="Enter your State"
                  />{" "}
                  <p className="text-danger">{error.state}</p>
                </Form.Group>
                <Button className="green-btn" type="submit">
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-4" md={6}>
          <LocationGoogleMap data={disData} center={center} type="clinic" />
        </Col>
      </Row>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </Container>
  );
};
export default ClinicAppointment;
