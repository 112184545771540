import React from "react";
import { Button, Container, Row, Col, Card, Image } from "react-bootstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../routes";
import verifiedImage from "../../assets/img/verifiedBadge.webp";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ThankYouPage = () => {
  const history = useHistory();
  const location = useLocation();

  const { image, heading, subHeading, buttonText, type } = location.state || {};

  console.log(image, "image");
  if (!image) {
    history.replace(Routes.Home.path);
    return;
  }

  const handleBackToHome = () => {
    if (type === "product_order") {
      history.replace(Routes.Home.path);
    } else {
      history.replace(Routes.Dispensaries.path);
    }
  };

  return (
    <Container fluid className={`p-${type == "product_order" ? "0" : "5"}`}>
      <Row className="justify-content-center">
        {/* <Col lg={6}> */}
        <Card className="text-center">
          <Card.Body className="p-3">
            <Row className="align-items-center">
              <Col xs={12} md={6} className="mb-0">
                <Image
                  src={image}
                  alt="Order Confirmation"
                  fluid
                  rounded
                  width={type === "product_order" ? 300 : 550}
                />
              </Col>
              <Col xs={12} md={6}>
                <h1 className="fs-2">
                  {heading}{" "}
                  <Image
                    src={verifiedImage}
                    alt="verified-check-image"
                    width={50}
                  />
                </h1>
                <p className="mt-3">{subHeading}</p>
                {type === "product_order" && (
                  <Card className="mt-4 p-3">
                    <h5>Apply / Renew MMJ</h5>{" "}
                    <a
                      href="https://app2.onlinemedicalcard.com/register"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        //   variant="secondary"
                        style={{
                          backgroundColor: "rgb(101 172 75 / 60%)",
                          position: "relative",
                        }}
                        className="mt-4 text-dark water-ripple-button"
                        //   onClick={handleBackToHome}
                      >
                        Click Here!!
                      </Button>
                    </a>
                    <span className="mmj-card-btn"></span>
                  </Card>
                )}
                <Button className="mt-4 green-btn" onClick={handleBackToHome}>
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* </Col> */}
      </Row>
    </Container>
  );
};

export default ThankYouPage;
