import { useEffect, useState } from "react";
import axios from "../../../axios";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";

import CustomPagination from "../../../components/Pagination";
import CustomToast from "../../../reusable/CustomToast";
import DispensaryClinicCard from "../../../components/DispensaryClinicCard";

const MyFavorites = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const getMyFavorites = async ({ queryKey }) => {
    const [, page] = queryKey;
    const res = await axios(`/get/wishlist/dispensary?page=${page}&limit=20`);
    return res.data.data;
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["favorites-list", currentPage],
    queryFn: getMyFavorites,
    keepPreviousData: true, // Keeps the previous data while fetching new data
    staleTime: 300000, // 5 minutes to reduce unnecessary refetches
  });

  useEffect(() => {
    dispatch(loadingSet(isLoading));
  }, [isLoading, dispatch]);

  if (error) {
    return (
      <ServerError
        errorMessage={error?.response?.data?.message || "An error occurred"}
      />
    );
  }

  return (
    <Container fluid>
      <h3 className="green-color mt-4">My Favorites</h3>
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : data?.dispensaries?.length ? (
        <Row>
          {data.dispensaries.map((disp) => (
            <Col lg={3} md={4} sm={6} key={disp.id}>
              <DispensaryClinicCard
                data={disp}
                refetchData={refetch}
                setShowToast={setShowToast}
                setToastBody={setToastBody}
                setSuccess={setSuccess}
              />
            </Col>
          ))}
          <CustomPagination
            totalPages={data.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      ) : (
        <p className="text-center">No Favorites</p>
      )}
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </Container>
  );
};

export default MyFavorites;
